import Pagination from "rc-pagination/lib/Pagination"
import React from "react"
import { Dropdown, DropdownButton, Form, Table } from "react-bootstrap"
import Moment from "react-moment"
import { Params, useParams } from "react-router"
import { useSearchParams } from "react-router-dom"
import {
	SearchTreatmentType,
	useSearchTreatment,
	useTreatmentStatusMutation,
} from "../../services/CelebTreatment.Service"
import { usePatientDialogStore } from "../../store/patientDailogStore"
import { useSidebarStore } from "../../store/sideBarStore"
import { useTaskCompletionDialogStore } from "../../store/taskCompletionDialogStore"
import { TreatmentStates } from "../../utils/appConstants"
import {
	CheckAdmin,
	CheckDoctor,
	CheckGenericPermission,
	CheckOperator,
} from "../permissions/CheckPermision"
import SortButton from "../utlity/SortButton"

const SearchTable = () => {
	return (
		<React.Suspense>
			<LandingPageImpl />
		</React.Suspense>
	)
}

const LandingPageImpl = () => {
	const paramsData: Readonly<Params<string>> = useParams()
	const [ searchParams, setSearchParams ] = useSearchParams()
	const newParams = new URLSearchParams( searchParams )
	const tasksStore = useTaskCompletionDialogStore()
	const patientStore = usePatientDialogStore()
	const [ currentPage, setCurrentPage ] = React.useState<number>( 0 )
	const status = searchParams.get( "status" )
	const [ sortingData, setSortingData ] = React.useState<string[] | null>()
	const searchPatient = searchParams.get( "searchPatient" )
	const sideBarStore = useSidebarStore()
	const { mutateAsync: updateTaskStatus } = useTreatmentStatusMutation()
	const { data: tableData } = useSearchTreatment( {
		search: searchPatient || "",
		limit: "10",
		offset: currentPage + "",
		serviceId: paramsData.id ? paramsData.id : "",
		...( sortingData && {
			[ "sortingParams" ]: JSON.stringify( sortingData ),
		} ),
	} )

	const idMapper = ( patientID: SearchTreatmentType ) => {
		if ( patientID.id ) {
			return patientID.id
		}
	}

	const addNewQueryParam = ( patientID: SearchTreatmentType ) => {
		const id = idMapper( patientID )
		const newParams = new URLSearchParams( searchParams )
		id && newParams.set( "PatientId", id )
		setSearchParams( newParams )
		sideBarStore.setTimelineData( patientID )
	}

	const onTreatmentStateChange = async (
		patientID: SearchTreatmentType,
		status: TreatmentStates,
	) => {
		const id = idMapper( patientID )
		id && updateTaskStatus( { treatmentId: id, status } )
	}

	const handleSortChange = ( apiParams: string[], sortOrder: "asc" | "desc" | undefined ): void => {
		const updatedSortingData = sortOrder ? [ ...apiParams, sortOrder ] : undefined
		setSortingData( updatedSortingData )
	}

	React.useEffect( () => {
		tableData?.treatment[ 0 ] && addNewQueryParam( tableData?.treatment[ 0 ] )
	}, [ tableData?.treatment[ 0 ], status ] )

	const onEdit = ( patientID: SearchTreatmentType ) => {
		const id = idMapper( patientID )
		id && patientStore.setTypesEditOpen( id )
	}

	console.log( tableData )
	return (
		<React.Suspense>
			<Table striped hover size="sm">
				<thead>
					<tr className="table-header">
						{status == "active" && <th>Check</th>}
						<th>
							Patient Name{" "}
							<SortButton sortKey="patient#firstName" onSortChange={handleSortChange} />
						</th>
						<th>
							Patient ID
							<SortButton sortKey="patient#entityId" onSortChange={handleSortChange} />
						</th>
						<th>
							Task{" "}
							<SortButton sortKey="tpProviderCategoryId#name" onSortChange={handleSortChange} />
						</th>
						<th>
							Submit Date
							<SortButton sortKey="createdAt" onSortChange={handleSortChange} />
						</th>
						<th>
							Clinic{" "}
							<SortButton
								sortKey="treatmentPlanDoctorAddress#name"
								onSortChange={handleSortChange}
							/>
						</th>
						<th>
							Doctor <SortButton sortKey="doctor#firstName" onSortChange={handleSortChange} />
						</th>
						<th>Action Buttons</th>
					</tr>
				</thead>
				<tbody>
					{tableData &&
						tableData?.treatment?.map( ( x: SearchTreatmentType ) => {
							return (
								<tr
									className="table-data"
									data-Active={newParams.get( "PatientId" ) === idMapper( x )}
									onClick={() => {
										addNewQueryParam( x )
									}}
								>
									{" "}
									{status == "active" && (
										<td onClick={( event ) => event?.stopPropagation()}>
											<span className="d-flex gap-2">
												{status == "active" && (
													<CheckGenericPermission genericLabel="Task Select">
														<Form.Check
															checked={
																tasksStore?.landingPageTasks.find( ( z ) => z.id === x?.id )
																	? true
																	: false
															}
														></Form.Check>
													</CheckGenericPermission>
												)}
											</span>
										</td>
									)}
									<td>
										<CheckGenericPermission genericLabel="Patient Name">
											{x?.patient.firstName + " " + x?.patient.lastName}
										</CheckGenericPermission>
									</td>
									<td>
										<CheckGenericPermission genericLabel="Patient ID">
											{x?.patient.entityId}
										</CheckGenericPermission>
									</td>
									<td>
										<CheckGenericPermission genericLabel="Patient ID">
											<CheckDoctor>{x?.tpConsumerCategoryId?.name}</CheckDoctor>
											<CheckOperator>{x?.tpProviderCategoryId?.name}</CheckOperator>
											<CheckAdmin>{x?.tpProviderCategoryId?.name}</CheckAdmin>
										</CheckGenericPermission>
									</td>
									<td>
										{" "}
										<CheckGenericPermission genericLabel="Patient Submit Date">
											{<Moment date={x?.patient.createdAt} format="MM/DD/YYYY" />}
										</CheckGenericPermission>
									</td>
									<td>
										{" "}
										<CheckGenericPermission genericLabel="Patient Clinic">
											{x?.treatmentPlanDoctorAddress.name}
										</CheckGenericPermission>
									</td>
									<td>
										{" "}
										<CheckGenericPermission genericLabel="Patient Assigned Doctor">
											{x?.doctor.firstName + " " + x?.doctor.lastName}
										</CheckGenericPermission>
									</td>
									<td>
										<div className="d-flex gap-2 justify-content-center">
											<CheckGenericPermission genericLabel={"View TP"}>
												<CheckDoctor>
													<a
														className="btn btn-primary"
														style={{ margin: "1px" }}
														target="_blank"
														href={
															"/editor/" +
															x?.tpConsumerCategoryId?.id +
															"?treatmentId=" +
															idMapper( x )
														}
													>
														View TP
													</a>
												</CheckDoctor>
												<CheckOperator>
													<a
														className="btn btn-primary"
														style={{ margin: "1px" }}
														target="_blank"
														href={
															"/editor/" +
															x?.tpProviderCategoryId?.id +
															"?treatmentId=" +
															idMapper( x )
														}
													>
														View TP
													</a>
												</CheckOperator>
												<CheckAdmin>
													<a
														className="btn btn-primary"
														style={{ margin: "1px" }}
														target="_blank"
														href={
															"/editor/" +
															x?.tpProviderCategoryId?.id +
															"?treatmentId=" +
															idMapper( x )
														}
													>
														View TP
													</a>
												</CheckAdmin>
											</CheckGenericPermission>
											<DropdownButton id="dropdown-basic-button" title="">
												<CheckGenericPermission genericLabel={"Patient Replace"}>
													{status == "completed" ? (
														<Dropdown.Item
															href="#PatientInfo"
															onClick={() => patientStore.setReplaceOpen( x?.id )}
														>
															Replace
														</Dropdown.Item>
													) : (
														""
													)}
												</CheckGenericPermission>
												<CheckGenericPermission genericLabel={"Patient Edit"}>
													<Dropdown.Item href="#PatientInfo" onClick={() => onEdit( x )}>
														Edit
													</Dropdown.Item>
												</CheckGenericPermission>
												<CheckGenericPermission genericLabel={"Patient Pause"}>
													<Dropdown.Item
														onClick={() => onTreatmentStateChange( x, TreatmentStates.PAUSED )}
													>
														Pause
													</Dropdown.Item>
												</CheckGenericPermission>
												<CheckGenericPermission genericLabel={"Patient Transfer"}>
													<Dropdown.Item>Transfer</Dropdown.Item>
												</CheckGenericPermission>
											</DropdownButton>
										</div>
									</td>
								</tr>
							)
						} )}
				</tbody>
			</Table>
			<Pagination
				className="pagination"
				style={{ marginTop: "-0.85%" }}
				total={typeof tableData?.total == "number" ? tableData.total : Number( tableData?.total )}
				pageSize={10}
				onChange={( pagenumber ) => setCurrentPage( ( pagenumber - 1 ) * 10 )}
				align={"center"}
			/>
		</React.Suspense>
	)
}

export default SearchTable
