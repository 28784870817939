import React from "react"
import { teethIdJSON } from "../../services/CelebPatient.Service"
import { toothCondition, useTeethStore } from "../../store/toothSelectionStore"
import CelebButtonTootheNumber from "../buttons/CelebButtonToothNumber"
export const ToothSelectionComponent = ( {
	usedFor,
	braceSelection,
}: {
	usedFor?: "extraction" | "bonding" | "teethReplacement"
	braceSelection?: {
		extraction: boolean | true
		lowerBracketSlotSize: boolean | true
		toothBonding: boolean | true
		upperBracketSlotSize: boolean | true
	}
} ) => {
	const {
		teethExtraction,
		toggleToothExtraction,
		toggleToothBonding,
		teethBonding,
		teethReplacement,
		toggleToothReplacment,
	} = useTeethStore()

	let itterativeCheck = { ...teethBonding }

	if ( usedFor == "extraction" ) {
		itterativeCheck = { ...teethExtraction }
	} else if ( usedFor == "teethReplacement" ) {
		itterativeCheck = { ...teethReplacement }
	} else if ( usedFor == "bonding" ) {
		itterativeCheck = { ...teethBonding }
	}

	const extractionCheck = ( toothId: string, isDisabled?: boolean ) => {
		if ( isDisabled ) {
			return
		}
		if ( usedFor == "extraction" ) {
			toggleToothExtraction( toothId )
			const check = teethBonding[ toothId ]
			check == "disabled"
				? toggleToothBonding( toothId, "available" )
				: toggleToothBonding( toothId, "disabled" )
			return
		} else if ( usedFor == "teethReplacement" ) {
			toggleToothReplacment( toothId )
		} else if ( usedFor == "bonding" ) {
			const check = teethExtraction[ toothId ]
			check == "available" ? toggleToothBonding( toothId ) : null
		}
	}

	return (
		<React.Fragment>
			<span
				className="d-flex justify-content-between"
				style={{ color: "#87ceeb", fontWeight: "bold", fontSize: "12px" }}
			>
				<p>R</p>
				<p>L</p>
			</span>
			<div className="d-flex justify-content-between w-100">
				<div className="d-flex">
					{Object.keys( teethIdJSON.upperSection.right )
						.reverse()
						.map( ( toothId ) => {
							const toothNumber = Number( toothId[ 2 ] )
							const active = itterativeCheck[ toothId ]
							const disabled = itterativeCheck.disabledUpper
							return (
								<div
									key={toothId}
									onClick={() => extractionCheck( toothId, braceSelection?.upperBracketSlotSize )}
								>
									<CelebButtonTootheNumber
										tootheNumber={toothNumber}
										active={active}
										disabled={disabled}
										isNoTreatmentSelected={braceSelection?.upperBracketSlotSize}
									/>
								</div>
							)
						} )}
					<div className="vr"></div>
					{Object.keys( teethIdJSON.upperSection.left )

						.map( ( toothId ) => {
							const toothNumber = Number( toothId[ 2 ] )
							const active = itterativeCheck[ toothId ]
							const disabled = itterativeCheck.disabledUpper
							return (
								<div
									key={toothId}
									onClick={( event ) =>
										extractionCheck( toothId, braceSelection?.upperBracketSlotSize )
									}
								>
									<CelebButtonTootheNumber
										tootheNumber={toothNumber}
										active={active}
										disabled={disabled}
										isNoTreatmentSelected={braceSelection?.upperBracketSlotSize}
									/>
								</div>
							)
						} )}
				</div>
			</div>
			<hr className="hr" />

			<div className="d-flex justify-content-between w-100">
				<div className="d-flex">
					{Object.keys( teethIdJSON.lowerSection.right )
						.reverse()
						.map( ( toothId ) => {
							const toothNumber = Number( toothId[ 2 ] )
							const active = itterativeCheck[ toothId ]
							const disabled = itterativeCheck.disabledLower
							return (
								<div
									key={toothId}
									onClick={() => extractionCheck( toothId, braceSelection?.lowerBracketSlotSize )}
								>
									<CelebButtonTootheNumber
										tootheNumber={toothNumber}
										active={active}
										disabled={disabled}
										isNoTreatmentSelected={braceSelection?.lowerBracketSlotSize}
									/>
								</div>
							)
						} )}
					<div className="vr"></div>
					{Object.keys( teethIdJSON.lowerSection.left )

						.map( ( toothId ) => {
							const toothNumber = Number( toothId[ 2 ] )
							const active = itterativeCheck[ toothId ]
							const disabled = itterativeCheck.disabledLower
							return (
								<div
									key={toothId}
									onClick={( event ) =>
										extractionCheck( toothId, braceSelection?.lowerBracketSlotSize )
									}
								>
									<CelebButtonTootheNumber
										tootheNumber={toothNumber}
										active={active}
										disabled={disabled}
										isNoTreatmentSelected={braceSelection?.lowerBracketSlotSize}
									/>
								</div>
							)
						} )}
				</div>
			</div>
		</React.Fragment>
	)
}

export const QuickSelection = () => {
	const { teethExtraction, toggleToothBonding } = useTeethStore()
	const quickToothSelection = (
		parameterSelection: number,
		section: "upperSection" | "lowerSection",
		selectionFunction: ( objectId: string, condition?: toothCondition ) => void,
	) => {
		Object.keys( teethIdJSON[ section ].left ).map( ( objectId, index ) => {
			if ( index >= parameterSelection ) return
			const check = teethExtraction[ objectId ]
			check == "available" ? selectionFunction( objectId ) : null
		} )
		Object.keys( teethIdJSON[ section ].right ).map( ( objectId, index ) => {
			if ( index >= parameterSelection ) return
			const check = teethExtraction[ objectId ]
			check == "available" ? selectionFunction( objectId ) : null
		} )
	}
	return (
		<React.Fragment>
			<section className="d-grid align-items-center">
				<div>
					Quick Select Upper
					<span
						className="ms-4 button-quick-select text-info"
						onClick={() => quickToothSelection( 5, "upperSection", toggleToothBonding )}
					>
						5-5
					</span>
					<span
						className="ms-2 button-quick-select text-info"
						onClick={() => quickToothSelection( 6, "upperSection", toggleToothBonding )}
					>
						6-6
					</span>
					<span
						className="ms-2 button-quick-select text-info"
						onClick={() => quickToothSelection( 7, "upperSection", toggleToothBonding )}
					>
						7-7
					</span>
				</div>
				<div className="">
					Quick Select Lower
					<span
						className="ms-4 button-quick-select text-info"
						onClick={() => quickToothSelection( 5, "lowerSection", toggleToothBonding )}
					>
						5-5
					</span>
					<span
						className="ms-2 button-quick-select text-info"
						onClick={() => quickToothSelection( 6, "lowerSection", toggleToothBonding )}
					>
						6-6
					</span>
					<span
						className="ms-2 button-quick-select text-info"
						onClick={() => quickToothSelection( 7, "lowerSection", toggleToothBonding )}
					>
						7-7
					</span>
				</div>
			</section>
		</React.Fragment>
	)
}
